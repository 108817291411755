import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  faqs =  [
    {     
      title: 'What is a vanity domain?',
      description: "this is a description"
    },
    {     
      title: 'Can I create a proxy to any domain?',
      description: "No, you can proxies only to domains you have added and verified"
    },
    {     
      title: 'How to create a proxy?',
      description: "No, you can proxies only to domains you have added and verified"
    }
  ]
  
}
