<nz-layout>
  <nz-header>
    <h1 style="font-family: 'Raleway', sans-serif;font-weight: 700; font-size:35px">branded.surf<small
        style="font-size: 15px"> BETA</small></h1>
  </nz-header>
  <nz-content>
    <div style="text-align: center;">
      <a class="landing-font hover-underline-animation" nz-button nzType="link">&nbsp;&nbsp;Pricing&nbsp;&nbsp;</a>
      <nz-divider class="landing-font" nzType="vertical"></nz-divider>
      <a class="landing-font hover-underline-animation" nz-button nzType="link">&nbsp;&nbsp;Docs&nbsp;&nbsp;</a>
      <nz-divider class="landing-font" nzType="vertical"></nz-divider>
      <a class="landing-font hover-underline-animation" nz-button nzType="link">&nbsp;&nbsp;Blog&nbsp;&nbsp;</a>
      <nz-divider class="landing-font" nzType="vertical"></nz-divider>
      <a class="landing-font login " nz-button nzType="link">&nbsp;&nbsp;Console&nbsp;&nbsp;</a>
    </div>
    <br><br>
    <!-- Hero screeen -->
    <div class="inner-content">
      <h1
        style="text-shadow: 0px -1px 0px rgba(255,255,255,.5);font-family: 'Raleway', sans-serif;font-weight: 700; text-align: center;font-size:90px">
        Custom domain for SAAS <br>
        made simple
      </h1>

      <h1 style="font-family: 'Raleway', sans-serif; font-weight: 100; text-align: center;"> Secure and easy way to
        allow customers <br>
        use their own domains to
        access your app
        <br><br>
        <a href="#" style="color: #177ddc; font-weight: 600;" class="hover-underline-animation">Sign up <i nz-icon nzType="arrow-right"
            nzTheme="outline"></i></a>
      </h1>
    </div>
    <nz-divider></nz-divider>

    <!-- Description -->
    <div class="inner-content" style='text-align: center;'>
      <h1 class="section-header">
        Built for the modern SAAS
      </h1>
      <br><br><br>
      <h1
        style="text-shadow: 0px -1px 0px rgba(255,255,255,.5);font-family: 'Raleway', sans-serif;font-weight: 300; text-align: center;">
        We take care of issuing, validating and renewing SSL certificates for your customer's domain and proxying
        requests from your SAAS domain allowing them to use their own domain to access your application.
      </h1>
      <br>
      <img src='assets/bs-info.svg' style="height:250px;">
    </div>
    <nz-divider></nz-divider>


    <!-- Features -->
    <div class="inner-content">
      <h1 class="section-header">
        Features
      </h1>
      <br><br><br>

      <div style="font-family: 'Raleway', sans-serif; font-weight: 100; text-align: center;">
        <div>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="11" [nzOffset]="1" class="feature-card">
              <div class='custom-font'>
                <i style="font-size: 50px" nz-icon nzType="global" nzTheme="outline"></i><br>
                <h1 class="section-header" style="font-weight:600;font-size:25px; padding: 20px">Globally distributed
                </h1>
                <h3 class="section-header" style="font-weight:200;font-size:20px; padding: 30px">
                  Our clusters are globally distributed across strategic locations that are closer to your customers.
                  Not sure where your users may come from? Simply, create a proxy and it will be replicated across all
                  our clusters with no additional cost to you!
                </h3>
              </div>
            </div>



            <div nz-col [nzSpan]="11" [nzOffset]="1" class="feature-card">
              <div class='custom-font'>
                <i style="font-size: 50px" nz-icon nzType="check-circle" nzTheme="outline"></i><br>
                <h1 class="section-header" style="font-weight:600;font-size:25px; padding: 20px">Simple setup</h1>
                <h3 class="section-header" style="font-weight:200;font-size:20px; padding: 30px">
                  TBD
                </h3>
              </div>
            </div>

          </div>
          <br><br><br>
          <div nz-row [nzGutter]="24">


            <div nz-col [nzSpan]="11" [nzOffset]="1" class="feature-card">
              <div class='custom-font'>
                <i style="font-size: 50px" nz-icon nzType="dollar" nzTheme="outline"></i><br>
                <h1 class="section-header" style="font-weight:600;font-size:25px; padding: 20px">Affordable pricing</h1>
                <h3 class="section-header" style="font-weight:200;font-size:20px; padding: 30px">
                  Our pricing is simple and transparent. You can have any number of domains or proxies for free, we only
                  charge for the bandwidth used by your proxies. Proxies will be deployed globally for free. Our
                  bandwidth charges are $5 for every 10GB of data.
                </h3>
              </div>
            </div>

            <div nz-col [nzSpan]="11" [nzOffset]="1" class="feature-card">
              <div class='custom-font'>
                <i style="font-size: 50px" nz-icon nzType="api" nzTheme="outline"></i><br>
                <h1 class="section-header" style="font-weight:600;font-size:25px; padding: 20px">API access</h1>
                <h3 class="section-header" style="font-weight:200;font-size:20px; padding: 30px">
                  Our API allows you to manage Domains and Proxies in a programmatic way using conventional HTTP
                  requests.
                  All of the functionality that you are familiar with in the BrandedSurf console is also available
                  through the API, allowing you to integrate in your system.
                </h3><a href="#">Documentation <i nz-icon nzType="arrow-right" nzRotate=325 nzTheme="outline"></i></a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <nz-divider></nz-divider>

    <!-- Pricing -->
    <div class="inner-content" style='text-align: center;'>
      <h1 class="section-header">
        Pricing
      </h1>
      <br><br><br>

      <div>
        <div nz-row>
          <div nz-col nzSpan="7"></div>
          <div nz-col nzSpan="10" class="feature-card">
            <div nz-row>
              <div nz-col nzSpan="12">
                <h3>Domain SSL management</h3>
              </div>
              <div nz-col nzSpan="12">
                <h3>Free</h3>
              </div>
            </div>
            <nz-divider></nz-divider>
            <div nz-row>
              <div nz-col nzSpan="12">
                <h3>Global deployment of proxies</h3>
              </div>
              <div nz-col nzSpan="12">
                <h3>Free</h3>
              </div>
            </div>
            <nz-divider></nz-divider>
            <div nz-row>
              <div nz-col nzSpan="12">
                <h3>Bandwidth usage <br>( Ingress + Egress )</h3>
              </div>
              <div nz-col nzSpan="12">
                <h3>$5 for every 10 GB <br>( Billed every month )</h3>
              </div>
            </div>
          </div>

          <div nz-col nzSpan="7"></div>
        </div>

      </div>
    </div> <br><br><br> <br><br><br>
    <nz-divider></nz-divider>

    <!-- FAQ -->
    <div class="inner-content" style='text-align: center;'>
      <h1 class="section-header">
        FAQ
      </h1>
      <br><br><br>
      <div>
        <div nz-row style="font-family: 'Raleway', sans-serif; text-align: center;">
          <div nz-col nzSpan="7"></div>
          <div nz-col nzSpan="10" class="feature-card">
            <nz-collapse nzGhost nzShowArrow="false">
              <nz-collapse-panel 
              *ngFor="let faq of faqs"
              [nzHeader]="faqHeaderTemplate">                
                <h3 >
                  {{faq.description}}
                </h3>
                <ng-template #faqHeaderTemplate>
                  <h2>{{faq.title}}</h2>
                </ng-template>
              </nz-collapse-panel>
            </nz-collapse>

            
          </div>

          <div nz-col nzSpan="7"></div>
        </div>

      </div> 
    </div> <br><br><br> <br><br><br>
    <nz-divider></nz-divider>
  </nz-content>
  <nz-footer>BrandedSurf ©2021 All rights reserved</nz-footer>
</nz-layout>